import Count from "../../components/Count/Count";
import Reels from "../../components/Reels/Reels";
import Saroop from "../../components/Saroop/Saroop";
import "./HomePage.scss";

function HomePage () {

    return(
        <>
        <Saroop/>
        <Count/>
        <Reels/>
        </>
        
    )
}


export default HomePage;