import Payment from "../../components/PaymentForm/Payment"
import PaymentFive from "../../components/PaymentForm/PaymentFive";
import "./FivePayment.scss"

function FivePayment () {

    return(
        <>
        <PaymentFive/>
        </>
        
    )
}


export default FivePayment;
