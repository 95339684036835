import Payment from "../../components/PaymentForm/Payment"
import "./TenPayment.scss"

function TenPayment () {

    return(
        <>
        <Payment/>
        </>
        
    )
}


export default TenPayment;