import About from "../../components/About/About";
import "./AboutPage.scss";

function AboutPage () {

    return(
        <>
        <About/>
        </>
    )
}


export default AboutPage;